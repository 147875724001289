<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      typeToSuffixPrefixMap: {
        variable: {
          prefix: "{{",
          suffix: "}}"
        },
        prompt: {
          prefix: "{{",
          suffix: "}}"
        },
        cav: {
          prefix: "{{",
          suffix: "}}"
        }
      },
      regExForVariable: new RegExp(`{{((?:.(?!{{))+?)(\\|cav)?}}`, "g"),
      regExForPrompt: new RegExp(`\\[\\[((?:.(?!\\[\\[))+?)]]`, "g"),
      regExForVariableNameContentEditable: new RegExp(
        '<span contenteditable="false" class="at_(?:variables|system|audio|secure|xSip|payment|cav)">{{([^}]*\\W[^}]*)}}<\\/span>|{{(\\w+?)}}',
        "g"
      ),
      regExForPromptNameContentEditable: new RegExp(
        '<span contenteditable="false" class="at_prompts">{{([^}]*\\W[^}]*)}}<\\/span>|{{(\\w+?)}}',
        "g"
      ),
      regExForVariableIdContentEditable: new RegExp(
        "{{(-?\\d+|XSIP_[\\S]+)\\|(?:variable|cav)}}",
        "g"
      ),
      regExForPromptIdContentEditable: new RegExp("{{(\\d+)\\|prompt}}", "g")
    };
  },
  computed: {
    ...mapState("prompts", {
      prompts: state => state.prompts
    }),
    ...mapGetters("variables", {
      variables: "variables",
      variableNames: "variableNames",
      xSipVariableNames: "xSipVariableNames",
      secureVariableNames: "secureVariableNames",
      audioVariables: "audioVariables",
      audioVariableNames: "audioVariableNames",
      getSystemVariableNames: "getSystemVariableNames",
      singleValuedVariables: "singleValuedVariables",
      secureVariables: "secureVariables",
      systemVariables: "systemVariables",
      xSipVariables: "xSipVariables",
      paymentVariables: "paymentVariables",
      paymentVariableNames: "paymentVariableNames",
      cavVariables: "cavVariables",
      cavVariablesNames: "cavVariablesNames"
    }),
    ...mapGetters("prompts", {
      promptNames: "promptNames"
    })
  },
  methods: {
    /**
     * this is used to check if the content include any system variables
     */
    systemVariablesUsed(items, prop = "variable_id") {
      return _.filter(this.systemVariables, variable =>
        items.includes(variable[prop].toString())
      );
    },
    /**
     * this is used to check if the content include any variables
     */
    dynamicContentUsed(parameterValue, regex) {
      parameterValue = parameterValue && parameterValue.toString();
      if (!parameterValue) {
        return [];
      }
      let matches = parameterValue.matchAll(regex);
      return _.map(Array.from(matches), match => {
        return match[1] || match[2];
      });
    },
    /**
     * The function will transfer all variables from name to id
     */
    parseVariablePopperContentForBackend(val) {
      let variablesUsed = this.dynamicContentUsed(val, this.regExForVariable);

      val = this.replaceNameWithIdOrIdWithName(
        val,
        "cav",
        variablesUsed,
        this.cavVariables,
        ["variable_name"],
        "variable_id"
      );

      val = this.replaceNameWithIdOrIdWithName(
        val,
        "variable",
        variablesUsed,
        this.variables,
        ["variable_name"],
        "variable_id"
      );

      let promptsUsed = this.dynamicContentUsed(val, this.regExForPrompt);
      val = this.replaceNameWithIdOrIdWithName(
        val,
        "prompt",
        promptsUsed,
        this.prompts,
        ["prompt_name"],
        "prompt_id"
      );
      return val;
    },
    /**
     * The function will transfer all variables' id to name
     * This is only for frontend contenteditable field to display correct format
     */
    parseContentEditableContentForFrontEnd(val) {
      let variablesUsed = this.dynamicContentUsed(
        val,
        this.regExForVariableIdContentEditable
      );
      val = this.replaceExistingContenteditable(val);

      val = this.replaceIdWithSpanForContentEditable(
        val,
        "variable",
        "at_xSip",
        variablesUsed,
        this.xSipVariables,
        ["variable_id"],
        "variable_name"
      );

      val = this.replaceIdWithSpanForContentEditable(
        val,
        "cav",
        "at_cav",
        variablesUsed,
        this.cavVariables,
        ["variable_id"],
        "variable_name"
      );
      val = this.replaceIdWithSpanForContentEditable(
        val,
        "variable",
        "at_system",
        variablesUsed,
        this.systemVariablesUsed(variablesUsed),
        ["variable_id"],
        "variable_name"
      );

      val = this.replaceIdWithSpanForContentEditable(
        val,
        "variable",
        "at_audio",
        variablesUsed,
        this.audioVariables,
        ["variable_id"],
        "variable_name"
      );

      val = this.replaceIdWithSpanForContentEditable(
        val,
        "variable",
        "at_variables",
        variablesUsed,
        this.singleValuedVariables,
        ["variable_id"],
        "variable_name"
      );

      val = this.replaceIdWithSpanForContentEditable(
        val,
        "variable",
        "at_secure",
        variablesUsed,
        this.secureVariables,
        ["variable_id"],
        "variable_name"
      );

      val = this.replaceIdWithSpanForContentEditable(
        val,
        "variable",
        "at_payment",
        variablesUsed,
        this.paymentVariables,
        ["variable_id"],
        "variable_name"
      );

      let promptsUsed = this.dynamicContentUsed(
        val,
        this.regExForPromptIdContentEditable
      );
      val = this.replaceIdWithSpanForContentEditable(
        val,
        "prompt",
        "at_prompts",
        promptsUsed,
        this.prompts,
        ["prompt_id"],
        "prompt_name"
      );
      //change all double quote and single quote to editable

      val = val
        .replace(/!!lt!!/g, `<b contenteditable="false"><i>&lt;`)
        .replace(/!!gt!!/g, "&gt;</i></b>");
      let re = new RegExp("<b.*?</b>", "g");
      this.contentWithoutSSML = val.replace(re, "");
      this.contentWithSSML = val;

      return val;
    },
    /**
     * The function will add a contenteditable true for all content in single and double quote
     * This is only for frontend popper field to display correct format
     */
    replaceExistingContenteditable(str) {
      //Remove existing span
      let orgStr = str;
      let spanRegex = /<span(.*?)>/g;
      let bTagRegex = /<b (.*?)>/g;
      let spanList;
      let spanListArray = [];
      let bTagList;
      let bTagListArray = [];

      while ((spanList = spanRegex.exec(orgStr))) {
        if (!spanListArray.includes(spanList)) {
          spanListArray.push(spanList.pop());
        }
      }
      spanListArray.forEach(function(itemTextDouble) {
        orgStr = orgStr.replaceAll(itemTextDouble, "");
      });

      while ((bTagList = bTagRegex.exec(orgStr))) {
        if (!bTagListArray.includes(bTagList)) {
          bTagListArray.push(bTagList.pop());
        }
      }
      bTagListArray.forEach(function(itemTextDouble) {
        orgStr = orgStr.replaceAll(itemTextDouble, "");
      });

      let regexDouble = /="(.*?)"/g;
      let regexSingle = /='(.*?)'/g;
      let currentStr;
      let newStr;

      const resultSingle = [];
      const resultDouble = [];
      while ((currentStr = regexDouble.exec(orgStr))) {
        if (!resultDouble.includes(currentStr)) {
          resultDouble.push(currentStr.pop());
        }
      }
      while ((currentStr = regexSingle.exec(orgStr))) {
        if (!resultSingle.includes(currentStr)) {
          resultSingle.push(currentStr.pop());
        }
      }
      resultDouble.forEach(function(itemTextDouble) {
        currentStr = '="' + itemTextDouble + '"';
        newStr =
          '="</i></b><b contenteditable="true"><i>' +
          itemTextDouble +
          '</i></b><b contenteditable="false"><i>"';
        str = str.replaceAll(currentStr, newStr);
      });
      resultSingle.forEach(function(itemTextSingle) {
        currentStr = "='" + itemTextSingle + "'";
        newStr =
          '=\'</i></b><b contenteditable="true"><i>' +
          itemTextSingle +
          '</i></b><b contenteditable="false"><i>\'';
        str = str.replaceAll(currentStr, newStr);
      });
      return str;
    },
    /**
     * The function will transfer all variables' id to name
     * This is only for frontend popper field to display correct format
     */
    parseVariablePopperContentForFrontend(val) {
      let variablesUsed = this.dynamicContentUsed(val, this.regExForVariable);
      val = this.replaceNameWithIdOrIdWithName(
        val,
        "variable",
        variablesUsed,
        this.variables,
        ["variable_id", "variable_name"],
        "variable_name"
      );
      val = this.replaceNameWithIdOrIdWithName(
        val,
        "cav",
        variablesUsed,
        this.cavVariables,
        ["variable_id", "variable_name"],
        "variable_name"
      );

      let promptsUsed = this.dynamicContentUsed(val, this.regExForPrompt);
      val = this.replaceNameWithIdOrIdWithName(
        val,
        "prompt",
        promptsUsed,
        this.prompts,
        ["prompt_id", "prompt_name"],
        "prompt_name"
      );
      return val;
    },
    /**
     * The function will transfer all variables' name to id
     * This is only for backend required
     */
    parseContentEditableContentForBackend(val) {
      let variablesUsed = this.dynamicContentUsed(
        val,
        this.regExForVariableNameContentEditable
      );

      val = this.replaceNameWithIdForContentEditable(
        val,
        "variable",
        "at_system",
        variablesUsed,
        this.systemVariablesUsed(variablesUsed, "variable_name"),
        ["variable_name"],
        "variable_id"
      );

      val = this.replaceNameWithIdForContentEditable(
        val,
        "variable",
        "at_audio",
        variablesUsed,
        this.audioVariables,
        ["variable_name"],
        "variable_id"
      );

      val = this.replaceNameWithIdForContentEditable(
        val,
        "variable",
        "at_variables",
        variablesUsed,
        this.singleValuedVariables,
        ["variable_name"],
        "variable_id"
      );

      val = this.replaceNameWithIdForContentEditable(
        val,
        "variable",
        "at_secure",
        variablesUsed,
        this.secureVariables,
        ["variable_name"],
        "variable_id"
      );

      val = this.replaceNameWithIdForContentEditable(
        val,
        "variable",
        "at_xSip",
        variablesUsed,
        this.xSipVariables,
        ["variable_name"],
        "variable_id"
      );

      val = this.replaceNameWithIdForContentEditable(
        val,
        "variable",
        "at_payment",
        variablesUsed,
        this.paymentVariables,
        ["variable_name"],
        "variable_id"
      );

      val = this.replaceNameWithIdForContentEditable(
        val,
        "cav",
        "at_cav",
        variablesUsed,
        this.cavVariables,
        ["variable_name"],
        "variable_id"
      );

      // if custom XSIP_* variables used, format it to conform to standard
      let re = new RegExp(`{{(XSIP_[\\S]+?)}}`, "g");
      let customXSipVariables = _.filter(
        this.dynamicContentUsed(val, re),
        xSipVariable => {
          return !xSipVariable.endsWith("|variable");
        }
      );
      let { prefix, suffix } = this.typeToSuffixPrefixMap["variable"];

      _.each(customXSipVariables, customXSipVariable => {
        let regExToReplace = new RegExp(`{{${customXSipVariable}}}`, "g");
        val = val.replace(
          regExToReplace,
          `${prefix}${customXSipVariable}|variable${suffix}`
        );
      });

      let promptsUsed = this.dynamicContentUsed(
        val,
        this.regExForPromptNameContentEditable
      );
      val = this.replaceNameWithIdForContentEditable(
        val,
        "prompt",
        "at_prompts",
        promptsUsed,
        this.prompts,
        ["prompt_name"],
        "prompt_id"
      );
      val = val.replace(/&nbsp;/g, " ");
      // val = he.decode(val);
      val = val
        .replace(/<font color="#e65855" size="2"><b><i>/g, "")
        .replace(/<\/i><\/b><\/font>/g, "")
        .replace(/<b contenteditable="false"><i>/g, "")
        .replace(/<\/i><\/b>/g, "")
        .replace(/<b contenteditable="true"><i>/g, "")
        .replace(/<\/i><\/b>/g, "")
        .replace(/<b><i>/g, "");
      val = val.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      return val;
    },
    /**
     * The function will transfer one type of variables' id to name
     */
    replaceNameWithIdOrIdWithName(
      content,
      toReplace,
      itemsToReplace,
      findFrom,
      propsToLookAt,
      replaceWith
    ) {
      let { prefix, suffix } = this.typeToSuffixPrefixMap[toReplace];
      _.map(itemsToReplace, itemToReplace => {
        let item = _.find(findFrom, item =>
          _.some(
            propsToLookAt,
            prop => String(item[prop]) === String(itemToReplace)
          )
        );
        if (!_.isEmpty(item)) {
          let escapedPrefix = _.escapeRegExp(prefix);
          let escapedSuffix = _.escapeRegExp(suffix);
          let re;
          // if toReplace is cav and replaceWith is variable_name, then we need to replace cav with variable_name
          if (toReplace === "cav" && replaceWith === "variable_name") {
            re = new RegExp(
              `${escapedPrefix}(${itemToReplace})\\|cav${escapedSuffix}`,
              "g"
            );
          } else {
            re = new RegExp(
              `${escapedPrefix}(${itemToReplace})${escapedSuffix}`,
              "g"
            );
          }
          let replacement = `${prefix}${item[replaceWith]}${suffix}`;
          // if toReplace is cav and replaceWith is variable_id, then we need append cav to the end of the variable_id
          if (toReplace === "cav" && replaceWith === "variable_id") {
            replacement = `${prefix}${item[replaceWith]}|cav${suffix}`;
          }
          content = content.replace(re, replacement);
        }
      });
      return content;
    },
    /**
     * The function will transfer one type of variables' name to id
     */
    replaceNameWithIdForContentEditable(
      content,
      toReplace,
      spanClass,
      itemsToReplace,
      findFrom,
      propsToLookAt,
      replaceWith
    ) {
      let { prefix, suffix } = this.typeToSuffixPrefixMap[toReplace];
      _.map(itemsToReplace, itemToReplace => {
        itemToReplace = itemToReplace
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">");
        let item = _.find(findFrom, item =>
          _.some(
            propsToLookAt,
            prop => String(item[prop]) === String(itemToReplace)
          )
        );
        if (
          !_.isEmpty(item) ||
          (spanClass === "at_xSip" &&
            /^XSIP_[\S]+/.test(itemToReplace.toString()))
        ) {
          let escapedPrefix = _.escapeRegExp(prefix);
          let escapedSuffix = _.escapeRegExp(suffix);
          let escapedItemToReplace = itemToReplace
            .replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

          let re = new RegExp(
            `(?:<span contenteditable="false" class="${spanClass}">)?${escapedPrefix}(${escapedItemToReplace})${escapedSuffix}(?:</span>)?`,
            "g"
          );
          content = content
            .replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">");

          if (!_.isEmpty(item)) {
            content = content.replace(
              re,
              `${prefix}${item[replaceWith]}|${toReplace}${suffix}`
            );
          } else {
            content = content.replace(
              re,
              `${prefix}${itemToReplace}|${toReplace}${suffix}`
            );
          }
        }
      });
      return content;
    },
    /**
     * The function will transfer one type of variables' id wrapped with span
     */
    replaceIdWithSpanForContentEditable(
      content,
      toReplace,
      spanClass,
      itemsToReplace,
      findFrom,
      propsToLookAt,
      replaceWith
    ) {
      let { prefix, suffix } = this.typeToSuffixPrefixMap[toReplace];
      _.map(itemsToReplace, itemToReplace => {
        let item = _.find(findFrom, item =>
          _.some(
            propsToLookAt,
            prop => String(item[prop]) === String(itemToReplace)
          )
        );
        if (
          !_.isEmpty(item) ||
          (spanClass === "at_xSip" &&
            /^XSIP_[\S]+/.test(itemToReplace.toString()))
        ) {
          let escapedPrefix = _.escapeRegExp(prefix);
          let escapedSuffix = _.escapeRegExp(suffix);
          let re = new RegExp(
            `${escapedPrefix}(${itemToReplace}\\|${toReplace})${escapedSuffix}`,
            "g"
          );

          let str;
          if (!_.isEmpty(item)) {
            str = `<span contenteditable="false" class="${spanClass}">${prefix}${item[replaceWith]}${suffix}</span>`;
          } else {
            str = `<span contenteditable="false" class="${spanClass}">${prefix}${itemToReplace}${suffix}</span>`;
          }

          // this.replacedEncodedStrings[str] = he.encode(str);
          content = content.replace(re, str);
        }
      });

      return content;
    }
  }
};
</script>
