<script>
import {
  closest,
  getOffset,
  getPrecedingRange,
  getRange,
  applyRange,
  scrollIntoView,
  getAtAndIndex
} from "@/utils/AtUtil";
import AtTemplate from "./AtTemplate.vue";
import _ from "lodash";
import { mapGetters, mapState } from "vuex";

export default {
  name: "AtComponent",
  mixins: [AtTemplate],
  props: {
    value: {
      type: String, // value not required
      default: null
    },
    at: {
      type: String,
      default: null
    },
    ats: {
      type: Array,
      default: () => ["{{"]
    },
    suffix: {
      type: String,
      default: " "
    },
    loop: {
      type: Boolean,
      default: true
    },
    allowSpaces: {
      type: Boolean,
      default: true
    },
    tabSelect: {
      type: Boolean,
      default: false
    },
    avoidEmail: {
      type: Boolean,
      default: true
    },
    showUnique: {
      type: Boolean,
      default: true
    },
    hoverSelect: {
      type: Boolean,
      default: true
    },
    members: {
      type: Object,
      default: () => {}
    },
    cavGroups: {
      type: Array,
      default: () => []
    },
    nameKey: {
      type: String,
      default: ""
    },
    filterMatch: {
      type: Function,
      // eslint-disable-next-line no-unused-vars
      default: (name, chunk, at) => {
        // match at lower-case
        return name.toLowerCase().indexOf(chunk.toLowerCase()) > -1;
      }
    },
    deleteMatch: {
      type: Function,
      default: (name, chunk, suffix) => {
        return chunk === name + suffix;
      }
    },
    scrollRef: {
      type: String,
      default: ""
    },
    popperClass: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      // at[v-model] mode should be on only when
      // initial :value/v-model is present (not nil)
      bindsValue: this.value != null,
      customsEmbedded: true,
      hasComposition: false,
      atwho: null,
      filteredMembers: null,
      suffixes: {
        "{{": "}}",
        "[[": "]]"
      }
    };
  },
  computed: {
    ...mapGetters("variables", {
      variables: "variables",
      audioVariables: "audioVariables"
    }),
    ...mapState("prompts", {
      prompts: state => state.prompts
    }),
    atItems() {
      return this.at ? [this.at] : this.ats;
    },

    currentItem() {
      if (this.atwho) {
        if (this.atwho.cur) {
          let current = this.atwho.cur.split("_");
          if (current.length > 1) {
            // return this.atwho.list[current[0]][current[1]];
            return _.get(this.atwho.list, `${current[0]}.${current[1]}`, "");
          }
          return "";
        } else {
          return "";
        }
      }
      return "";
    },

    style() {
      if (this.atwho) {
        const { x, y } = this.atwho;
        const { wrap } = this.$refs;
        if (wrap) {
          const offset = getOffset(wrap);
          const scrollLeft = this.scrollRef
            ? document.querySelector(this.scrollRef).scrollLeft
            : 0;
          const scrollTop = this.scrollRef
            ? document.querySelector(this.scrollRef).scrollTop
            : 0;
          // const left =
          //   x + scrollLeft + window.pageXOffset - 2 * (offset.left - 10) + "px";
          //
          // // console.log(ce.left, x, offset.left, scrollLeft, window.pageXOffset);
          // // const left = ce.left + "px";
          //
          // console.log(
          //   y,
          //   scrollTop,
          //   window.pageYOffset,
          //   offset.top,
          //   2 * offset.top - y + 10
          // );
          // const top = 2 * offset.top - y + 10 + "px";
          // // 165 + y + scrollTop + window.pageYOffset - offset.top + "px";
          const left = x + scrollLeft + window.pageXOffset - offset.left + "px";
          const top =
            165 + y + scrollTop + window.pageYOffset - offset.top + "px";
          return { left, top, "z-index": "111111 !important" };
        }
      }
      return null;
    },
    prefixToProp() {
      const groups = Object.keys(this.members);
      return {
        "{{": groups
      };
    }
  },
  watch: {
    "atwho.cur"(index) {
      if (index != null) {
        // cur index exists
        this.$nextTick(() => {
          this.scrollToCur();
        });
      }
    },
    members() {
      this.handleInput(true);
    },
    value(value) {
      if (this.bindsValue) {
        this.handleValueUpdate(value);
      }
    }
  },
  mounted() {
    if (this.$scopedSlots.embeddedItem) {
      this.customsEmbedded = true;
    }
    if (this.bindsValue) {
      this.handleValueUpdate(this.value);
    }
  },

  methods: {
    itemName(v) {
      const { nameKey } = this;
      return nameKey ? v[nameKey] : v;
    },
    isCur(index) {
      return index === this.atwho.cur;
    },
    handleValueUpdate(value) {
      const el = this.$el.querySelector("[contenteditable]");
      if (value !== el.innerHTML) {
        // avoid range reset
        el.innerHTML = value;
        this.dispatchInput();
      }
    },
    dispatchInput() {
      let el = this.$el.querySelector("[contenteditable]");
      let ev = new Event("input", { bubbles: true });
      el.dispatchEvent(ev);
    },

    handleItemHover(e) {
      if (this.hoverSelect) {
        this.selectByMouse(e);
      }
    },
    handleItemClick(e) {
      this.selectByMouse(e);
      this.insertItem();
    },
    handleDelete(e) {
      const range = getPrecedingRange();
      if (range) {
        // fixme: Very bad code from me
        if (this.customsEmbedded && range.endOffset >= 1) {
          let a =
            range.endContainer.childNodes[range.endOffset] ||
            range.endContainer.childNodes[range.endOffset - 1];
          if (!a || (a.nodeType === Node.TEXT_NODE && !/^\s?$/.test(a.data))) {
            return;
          } else if (a.nodeType === Node.TEXT_NODE) {
            if (a.previousSibling) a = a.previousSibling;
          } else {
            if (a.previousElementSibling) a = a.previousElementSibling;
          }
          let ch = [].slice.call(a.childNodes);
          ch = [].reverse.call(ch);
          ch.unshift(a);
          let last;
          [].some.call(ch, c => {
            if (c.getAttribute && c.getAttribute("data-at-embedded") != null) {
              last = c;
              return true;
            }
          });
          if (last) {
            e.preventDefault();
            e.stopPropagation();
            const r = getRange();
            if (r) {
              r.setStartBefore(last);
              r.deleteContents();
              applyRange(r);
              this.handleInput();
            }
          }
          return;
        }

        const { atItems, members, deleteMatch, itemName } = this;
        const text = range.toString();
        const { at, index } = getAtAndIndex(text, atItems);

        if (index > -1) {
          const chunk = text.slice(index + at.length);

          // let matched = {};
          let self = this;
          const has = _.some(
            _.map(members, (member, key) => {
              if (!_.isEmpty(members[key])) {
                return _.some(members[key], function(value) {
                  const name = itemName(value);
                  return deleteMatch(name, chunk, self.suffixes[at]);
                });
              }
            }),
            group => group
          );

          // const has = _.some(matched, group => group);

          if (has) {
            e.preventDefault();
            e.stopPropagation();
            const r = getRange();
            if (r) {
              r.setStart(r.endContainer, index);
              r.deleteContents();
              applyRange(r);
              this.handleInput();
            }
          }
        }
      }
    },
    handleKeyDown(e) {
      const { atwho } = this;
      if (atwho) {
        if (e.keyCode === 38 || e.keyCode === 40) {
          // ↑/↓
          if (!(e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            e.stopPropagation();
            this.selectByKeyboard(e);
          }
          return;
        }
        if (
          e.keyCode === 13 ||
          e.keyCode === 32 ||
          (this.tabSelect && e.keyCode === 9)
        ) {
          // enter or space or tab
          this.insertItem();
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        if (e.keyCode === 27 || (!this.tabSelect && e.keyCode === 9)) {
          // esc or tab away
          this.closePanel();
          return;
        }
      }

      // 为了兼容ie ie9~11 editable无input事件 只能靠keydown触发 textarea正常
      // 另 ie9 textarea的delete不触发input
      const isValid = (e.keyCode >= 48 && e.keyCode <= 90) || e.keyCode === 8;
      if (isValid) {
        setTimeout(() => {
          this.handleInput();
        }, 50);
      }

      if (e.keyCode === 8) {
        this.handleDelete(e);
      }
    },

    // compositionStart -> input -> compositionEnd
    handleCompositionStart() {
      this.hasComposition = true;
    },
    handleCompositionEnd() {
      this.hasComposition = false;
      this.handleInput();
    },
    handleInput(keep) {
      if (this.hasComposition) return;
      const el = this.$el.querySelector("[contenteditable]");
      this.$emit("input", el.innerHTML);

      const range = getPrecedingRange();
      if (range) {
        const { atItems, avoidEmail, allowSpaces, showUnique } = this;

        let show = true;
        const text = range.toString();

        const { at, index } = getAtAndIndex(text, atItems);

        if (index < 0) show = false;
        const prev = text[index];

        const chunk = text.slice(index + at.length, text.length);

        if (avoidEmail) {
          if (/^[a-z0-9]$/i.test(prev)) show = false;
        }

        if (!allowSpaces && /\s/.test(chunk)) {
          show = false;
        }
        if (/^\s/.test(chunk)) show = false;

        if (!show) {
          this.closePanel();
        } else {
          const { members, filterMatch, itemName } = this;
          if (!keep && chunk) {
            // fixme: should be consistent with AtTextarea.vue
            this.$emit("at", chunk);
          }

          let matched = {};

          _.map(members, (member, key) => {
            if (!_.isEmpty(members[key])) {
              const filterMembers = _.filter(members[key], function(value) {
                const name = itemName(value);
                return filterMatch(name, chunk, at);
              });
              if (!_.isEmpty(filterMembers)) {
                matched[key] = filterMembers;
              }
            }
          });
          let keysToFind = this.prefixToProp[at];
          let matchedProps = {};

          _.map(keysToFind, keyToFind => {
            if (!_.isEmpty(matched[keyToFind])) {
              matchedProps[keyToFind] = _.cloneDeep(matched[keyToFind]);
            }
          });

          this.filteredMembers = _.cloneDeep(matchedProps);
          show = false;
          if (!_.isEmpty(matchedProps)) {
            show = true;
            if (!showUnique) {
              let item = matchedProps[0];
              if (chunk === itemName(item)) {
                show = false;
              }
            }
          }

          if (show) {
            this.openPanel(matchedProps, range, index, at);
          } else {
            this.closePanel();
          }
        }
      }
    },

    closePanel() {
      if (this.atwho) {
        this.atwho = null;
      }
    },
    openPanel(list, range, offset, at) {
      const fn = () => {
        const el = this.$el.querySelector("[contenteditable]");
        const r = range.cloneRange();
        r.setStart(r.endContainer, offset + at.length);
        const rect = r.getClientRects()[0];
        const contentEditablePos = el.getBoundingClientRect();
        this.atwho = {
          range,
          offset,
          list,
          x: rect.left + contentEditablePos.left,
          y: rect.top - 4 + contentEditablePos.top,
          cur: _.head(_.keys(this.filteredMembers)) + "_0"
        };
      };
      if (this.atwho) {
        fn();
      } else {
        setTimeout(fn, 10);
      }
    },

    scrollToCur() {
      const curEl = _.get(this.$refs.cur, 0);
      if (curEl) {
        const scrollParent =
          curEl.parentElement.parentElement.parentElement.parentElement; // .atwho-view
        scrollIntoView(curEl, scrollParent);
      }
    },
    selectByMouse(e) {
      const el = closest(e.target, d => {
        return d.getAttribute("data-index");
      });
      const cur = el.getAttribute("data-index");
      this.atwho = {
        ...this.atwho,
        cur
      };
    },
    selectByKeyboard(e) {
      const offset = e.keyCode === 38 ? -1 : 1;
      const { cur, list } = this.atwho;

      let matchers = [];

      _.map(list, (listItems, key) => {
        _.map(listItems, (listItem, index) => {
          matchers.push(`${key}_${index}`);
        });
      });

      let index = _.findIndex(matchers, matcher => _.isEqual(matcher, cur));

      const nextCur = this.loop
        ? (index + offset + matchers.length) % matchers.length
        : Math.max(0, Math.min(index + offset, matchers.length - 1));
      this.atwho = {
        ...this.atwho,
        cur: matchers[nextCur]
      };
    },

    insertText(text, r) {
      r.deleteContents();
      const node = r.endContainer;
      if (node.nodeType === Node.TEXT_NODE) {
        const cut = r.endOffset;
        node.data = node.data.slice(0, cut) + text + node.data.slice(cut);
        r.setEnd(node, cut + text.length);
      } else {
        const t = document.createTextNode(text);
        r.insertNode(t);
        r.setEndAfter(t);
      }
      r.collapse(false);
      applyRange(r);
      this.dispatchInput();
    },

    insertHTML(html) {
      var sel, range;
      if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          range.deleteContents();

          // Range.createContextualFragment() would be useful here but is
          // non-standard and not supported in all browsers (IE9, for one)
          var el = document.createElement("div");
          el.innerHTML = html;
          var frag = document.createDocumentFragment(),
            node,
            lastNode;
          while ((node = el.firstChild)) {
            lastNode = frag.appendChild(node);
          }
          range.insertNode(frag);

          // Preserve the selection
          if (lastNode) {
            range = range.cloneRange();
            range.setStartAfter(lastNode);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
          }
        }
      } else if (document.selection && document.selection.type != "Control") {
        // IE < 9
        document.selection.createRange().pasteHTML(html);
      }
    },

    // insertHtml(html, r, at, at_class) {
    //   r.deleteContents();
    //   const node = r.endContainer;
    //   var newElement = document.createElement("span");
    //
    //   // Seems `contentediable=false` should includes spaces,
    //   // otherwise, caret can't be placed well across them
    //   newElement.appendChild(this.htmlToElement(html));
    //   newElement.setAttribute("contenteditable", false);
    //   newElement.setAttribute("class", at_class);
    //
    //   if (node.nodeType === Node.TEXT_NODE) {
    //     const cut = r.endOffset;
    //     var secondPart = node.splitText(cut);
    //     node.parentNode.insertBefore(newElement, secondPart);
    //     r.setEndBefore(secondPart);
    //   } else {
    //     const t = document.createTextNode(this.suffixes[at]);
    //     r.insertNode(newElement);
    //     r.setEndAfter(newElement);
    //     r.insertNode(t);
    //     r.setEndAfter(t);
    //   }
    //   r.collapse(false);
    //   applyRange(r);
    // },

    insertItem() {
      const { range, list, cur } = this.atwho;
      const { atItems, itemName, customsEmbedded } = this;
      const r = range.cloneRange();
      const text = range.toString();
      const { at, index } = getAtAndIndex(text, atItems);

      // Leading `@` is automatically dropped as `customsEmbedded=true`
      // You can fully custom the output inside the embedded slot
      const start = customsEmbedded ? index : index + at.length;
      r.setStart(r.endContainer, start);

      applyRange(r);
      const current = cur.split("_");
      const curItem = list[current[0]][current[1]];
      const group = current[0];
      // const id = +current[1];
      // const default_value = this.getGroupDefaultValue(group, id);
      const html = at + itemName(curItem) + this.suffixes[at];
      const spanClass = this.cavGroups.includes(group)
        ? "at_cav"
        : `at_${group}`;
      // const final_html =
      //   '<span title="' +
      //   default_value +
      //   '" slot="reference" contenteditable="false" class="at_' +
      //   group +
      //   '">' +
      //   html +
      //   "</span>";
      if (customsEmbedded) {
        // `suffix` is ignored as `customsEmbedded=true` has to be
        // wrapped around by spaces
        // this.insertHtml(html, r, at, "at_" + group);
        this.insertHTML(
          `<span contenteditable="false" class="${spanClass}">${html}</span>&nbsp;`
        );
      } else {
        const t = itemName(curItem) + this.suffixes[at] + " ";
        this.insertText(t, r);
      }

      this.$emit("insert", curItem);
      this.closePanel();
    },

    // htmlToElement(html) {
    //   var template = document.createElement("template");
    //   html = html.trim(); // Never return a text node of whitespace as the result
    //   template.innerHTML = html;
    //   return template.content.firstChild;
    // },

    getGroupDefaultValue(group, id) {
      let default_value = "";
      switch (group) {
        case "variables": {
          const variable = this.variables[id];
          default_value = variable.default_value;
          break;
        }
        case "prompts": {
          const prompt = this.prompts[id];
          default_value = prompt.tts_value;
          break;
        }
        default:
      }
      return default_value;
    }
  }
};
</script>
